var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-steps",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { active: _vm.active, "finish-status": "success", simple: "" },
        },
        [
          _c("el-step", { attrs: { title: "注册公司" } }),
          _c("el-step", { attrs: { title: "注册用户" } }),
        ],
        1
      ),
      _c("div", { staticClass: "login-form-box" }, [
        _vm.active === 0
          ? _c("div", [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "company",
                      attrs: {
                        "label-width": "100px",
                        rules: _vm.rules.corp,
                        model: _vm.corpForm,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司名称", prop: "companyName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              placeholder: "请输入公司名称",
                            },
                            model: {
                              value: _vm.corpForm.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.corpForm, "companyName", $$v)
                              },
                              expression: "corpForm.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司分类", prop: "tenantId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                size: "small",
                                placeholder: "请选择公司分类",
                              },
                              model: {
                                value: _vm.corpForm.tenantId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.corpForm, "tenantId", $$v)
                                },
                                expression: "corpForm.tenantId",
                              },
                            },
                            _vm._l(_vm.roleTypes, function (item) {
                              return _c("el-option", {
                                key: item.name,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址", prop: "address" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "请输入地址" },
                            model: {
                              value: _vm.corpForm.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.corpForm, "address", $$v)
                              },
                              expression: "corpForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "contact" } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              placeholder: "请输入联系人",
                            },
                            model: {
                              value: _vm.corpForm.contact,
                              callback: function ($$v) {
                                _vm.$set(_vm.corpForm, "contact", $$v)
                              },
                              expression: "corpForm.contact",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "电话", prop: "tel" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", placeholder: "请输入电话" },
                            model: {
                              value: _vm.corpForm.tel,
                              callback: function ($$v) {
                                _vm.$set(_vm.corpForm, "tel", $$v)
                              },
                              expression: "corpForm.tel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("dialog-footer", {
                    attrs: { "ok-text": "下一步" },
                    on: {
                      reset: function ($event) {
                        return _vm.onCancel("company")
                      },
                      submit: _vm.onCorpSubmit,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.active === 1
          ? _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "user",
                    attrs: {
                      "label-width": "120px",
                      rules: _vm.rules.user,
                      model: _vm.userForm,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "公司名称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: true,
                            placeholder: "请输入公司名称",
                            readonly: true,
                          },
                          model: {
                            value: _vm.corpForm.companyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.corpForm, "companyName", $$v)
                            },
                            expression: "corpForm.companyName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "username", label: "用户名" } },
                      [
                        _c("el-input", {
                          attrs: { size: "small", placeholder: "请输入用户名" },
                          model: {
                            value: _vm.userForm.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.userForm, "username", $$v)
                            },
                            expression: "userForm.username",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "password", label: "用户密码" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            type: "password",
                            placeholder: "请输入密码",
                          },
                          model: {
                            value: _vm.userForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.userForm, "password", $$v)
                            },
                            expression: "userForm.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "password2", label: "二次确认密码" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            type: "password",
                            placeholder: "请输入确认密码",
                          },
                          model: {
                            value: _vm.userForm.password2,
                            callback: function ($$v) {
                              _vm.$set(_vm.userForm, "password2", $$v)
                            },
                            expression: "userForm.password2",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "mobile", label: "手机" } },
                      [
                        _c("el-input", {
                          attrs: { size: "small", placeholder: "请输入手机号" },
                          model: {
                            value: _vm.userForm.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.userForm, "mobile", $$v)
                            },
                            expression: "userForm.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("dialog-footer", {
                  on: {
                    reset: function ($event) {
                      return _vm.onCancel("user")
                    },
                    submit: _vm.onUserSubmit,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }