<template>
  <div class="login-box">
    <!-- <div class="corp-name-box">
      <div>杭州衡驰科技有限公司</div>
      <div>Hangzhou i-HC Technology Co., Ltd.</div>
    </div> -->
    <el-form
      v-loading="loading"
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      label-position="left"
      label-width="0px"
      class="login-form"
    >
      <div class="login-title-box">
        <h3 class="login-title">智能监控平台</h3>
      </div>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="请输入用户名">
          <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="密码"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0 0 25px 0;">记住我</el-checkbox> -->
      <el-form-item style="width:100%;">
        <el-button
          size="medium"
          type="primary"
          style="width:100%;"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
      <div class="register-box">
        <el-button type="text" @click="onOpenRegisterDialog">新用户注册</el-button>
      </div>
    </el-form>
    <!--  底部  -->
    <el-dialog title="新用户注册" destroy-on-close :close-on-press-escape="false" show-close
      v-if="dialogVisible" :visible="dialogVisible" :close-on-click-modal="true" v-loading="dLoading"
      @close="onCloseDialog" width="600px"
    >
      <edit ref="edit" :visible="dialogVisible"  @close-edit-modal="onCloseDialog(true)" @loading-start="loadingStart" @loading-end="loadingEnd" />
    </el-dialog>
    <!-- <div v-if="$store.state.settings.showFooter" id="el-login-footer">
      <span v-html="$store.state.settings.footerTxt" />
      <span>⋅</span>
      <a href="http://www.beian.miit.gov.cn" target="_blank">{{ $store.state.settings.caseNumber }}</a>
    </div> -->
  </div>
</template>

<script>
import edit from "./edit";
export default {
  name: 'Login',
  components: {
    edit
  },
  data() {
    return {
      dialogVisible: false,
      loginForm: {
        username: '',
        password: '',
        rememberMe: false,
        code: ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '用户名不能为空' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' }
        ],
        code: [{ required: true, trigger: 'change', message: '验证码不能为空' }]
      },
      loading: false,
      dLoading: false,
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        const user = {
          username: this.loginForm.username,
          password: this.loginForm.password,
          rememberMe: this.loginForm.rememberMe
        };
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch('Login', user)
            .then(() => {
              const user = JSON.parse(window.sessionStorage.getItem('USER'));
              this.loading = false;
              this.$router.push({ name: 'homepage' });
            })
            .catch((e) => {
              this.$message.error(e.response.data.message);
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    onOpenRegisterDialog () {
      this.dialogVisible = true;
    },
    async onCloseDialog (ignore) {
			// 通过 emit 发送的关闭请求一律通过
			// 如果点击 close 图标则一律直接关闭
			if (ignore || !this.$refs.edit || await this.$refs.edit.onClosed()) {
				// 设置 visable 后,会重新触发 close 所以需要先 remove edit
				delete this.$refs.edit;
        this.dialogVisible = false;
			}
    },
    loadingStart () {
      this.dLoading = true;
    },
    loadingEnd () {
      this.dLoading = false;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  // background: #eaeaea;
  background-size: cover !important;
  background: url('../../assets/images/login-bg.jpg');

  .el-dialog {
    box-shadow: 10px 10px 10px rgba($color: #303947, $alpha: 0.4);
    border-radius: 0.4vw;
    border: 3px solid #388ab3;
    background: linear-gradient(#2071ab, #111c3b);
  }

  .el-dialog__title, .el-dialog__close {
    color: #FFFFFF;
  }
}

.corp-name-box {
  position: absolute;
  top: 40px;
  left: 40px;
  color: #FFFFFF;
  padding-left: 55px;
  background-size: 42px;
  background-position: left;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/logo.png');

  >div:first-child {
    margin-top: 3px;
    font-size: 22px;
    margin-bottom: 5px;
  }
}

.login-form {
  color: rgba($color:#303133, $alpha: 0.8);
  // border-radius: 6px;
  position: absolute;
  box-shadow: 3px 3px 10px #AAA;
  left: 34%;
  top: 20vh;
  background: #ffffff;
  width: 400px;
  height: 60vh;
  max-height: 550px;
  padding: 100px 25px 150px 25px;

  .login-title-box {
    text-align: center;
    width: 100%;
    margin: auto;
    .login-title {
      width: 150px;
      margin: 0px auto 30px auto;
      margin-bottom: 30px;
      // background-size: 30px;
      // background-position: left;
      // background-repeat: no-repeat;
      // background-image: url('../../assets/images/logo.png');
      text-align: left;
      padding-left: 17px;
      height: 40px;
      line-height: 40px;
    }
  }

  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 20px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  display: inline-block;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.register-box {
  width: 100%;
  text-align: right;
  margin-top: -10px;
  margin-bottom: 15px;
  cursor: pointer;
}
</style>
