import request from '@/utils/request';

// 列表
export const list = (params) => {
    const rand = Date.now();
    return request.get(`companies/queryByPage?rand`, { params });
}
// 增加
export const add = (data) => {
    const rand = Date.now();
    return request.post(`companies?${rand}`, data);
}

export const addWithoutToken = (data) => {
    const rand = Date.now();
    return request.post(`companies/add?${rand}`, data);
}

// 编辑
export const edit = (data) => {
    const rand = Date.now();
    return request.put(`companies/${data.id}?${rand}`, data);
}
// 删除
export const del = (id) => {
    const rand = Date.now();
    return request.get(`companies/delete/${id}?${rand}`);
}
// 详情
export const detail = (id) => {
    const rand = Date.now();
    return request.get(`companies/${id}?${rand}`);
}

export const listDetail = async (params) => {
    const rand = Date.now();
    const company = await request.get(`companies/listt/?${rand}`, { params });
    if (!company || !company.data) return null;
    return company.data[0];
}

export const delFile = (params) => {
    const rand = Date.now();
    return request.get(`companies/delete/File`, { params });
}

export default {
    list,
    add,
    addWithoutToken,
    edit,
    del,
    delFile,
    detail,
    listDetail
}