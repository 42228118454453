<template>
  <div>
    <el-steps :active="active" finish-status="success" style="margin-bottom: 20px" simple>
      <el-step title="注册公司"></el-step>
      <el-step title="注册用户"></el-step>
    </el-steps>
    <div class="login-form-box">
      <div v-if="active === 0">
        <div>
          <el-form label-width="100px" :rules="rules.corp" ref="company" :model="corpForm" v-loading="loading">
              <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="corpForm.companyName" size="small" placeholder="请输入公司名称"></el-input>
              </el-form-item>
              <el-form-item label="公司分类" prop="tenantId">
          <el-select v-model="corpForm.tenantId" clearable size="small" style="width:100%;" placeholder="请选择公司分类">
              <el-option v-for="item in roleTypes" :key="item.name" :label="item.label" :value="item.value"></el-option>
          </el-select>
              </el-form-item>
              <el-form-item label="地址" prop="address">
          <el-input v-model="corpForm.address" size="small" placeholder="请输入地址"></el-input>
              </el-form-item>
              <el-form-item label="联系人" prop="contact">
          <el-input v-model="corpForm.contact" size="small" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="tel">
          <el-input v-model="corpForm.tel" size="small" placeholder="请输入电话"></el-input>
              </el-form-item>
          </el-form>
          <dialog-footer ok-text="下一步" @reset="onCancel('company')" @submit="onCorpSubmit" />
        </div>
      </div>
      <div v-if="active === 1">
        <el-form label-width="120px" :rules="rules.user" ref="user" :model="userForm" v-loading="loading">
          <el-form-item label="公司名称">
            <el-input size="small" :disabled="true" placeholder="请输入公司名称" :readonly="true" v-model="corpForm.companyName"></el-input>
          </el-form-item>
          <el-form-item prop="username" label="用户名">
            <el-input size="small" v-model="userForm.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="用户密码">
            <el-input size="small" type="password" v-model="userForm.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item prop="password2" label="二次确认密码">
            <el-input size="small" type="password" v-model="userForm.password2" placeholder="请输入确认密码"></el-input>
          </el-form-item>
          <!-- <el-form-item prop="email" label="邮箱">
            <el-input size="small" placeholder="请输入邮箱" v-model="userForm.email"></el-input>
          </el-form-item> -->
          <el-form-item prop="mobile" label="手机">
            <el-input size="small" placeholder="请输入手机号" v-model="userForm.mobile"></el-input>
          </el-form-item>
        </el-form>
        <dialog-footer @reset="onCancel('user')" @submit="onUserSubmit" />
      </div>
    </div>
  </div>
</template>
<script>
import bizApi from "@/api/business";
import userApi from "@/api/user";

export default {
  data () {
    return {
      active: 0,
      loading: false,
      roleTypes: [
        {
          label: "代理",
          value: 1
        },
        {
          label: "客户",
          value: 2
        }
      ],
      rules: {
        corp: {
          companyName: [
              { required: true, message: '请输入公司名称', trigger: 'blur' }
          ],
          tenantId: [
              { required: true, message: '请选择一个分组', trigger: 'blur' }
          ],
          address: [
              { required: true, message: '请输入地址', trigger: 'blur' }
          ],
          contact: [
              { required: true, message: '请输入联系人', trigger: 'blur' }
          ],
          tel: [
            { required: true, message: '请输入电话', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (!/^1\d{10}$/.test(value)) {
                callback("手机号格式错误");
                return;
                }
                callback();
              }
            }
          ]
        },
        user: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' }
          ],
          tenantId: [
            { required: true, message: '请选择一个公司分类', trigger: 'change' }
          ],
          businessId: [
            { required: true, message: '请选择一个公司', trigger: 'change' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (value.length < 6) {
                  callback("密码长度不能小于六位");
                  return;
                }
                let code = 0;
                if (/\d/.test(value)) {
                  code++;
                }
                if (/[a-zA-Z]/.test(value)) {
                  code++;
                }
                if (code < 2) {
                  callback("密码中至少要包含数字和字母");
                  return;
                }

                callback();
              }
            }
          ],
          password2: [
            { required: true, message: '请输入二次密码', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (this.userForm.password !== value) {
                  callback("输入的密码和二次确认密码不一致");
                  return;
                }
                callback();
              }
            }
          ],
          mobile: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (!/^1\d{10}$/.test(value)) {
                  callback("手机号格式错误");
                  return;
                }
                callback();
              }
            }
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { required: true, type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
          ]
        }
      },
      corpForm: {
        companyName: "",
        tenantId: "",
        address: "",
        contact: "",
        tel: "",
        // 创建公司时，默认给出所需要的权限
        type: 1, // 默认管理公司
        configs: $statics.DEFAULT_AUTHORITY_CONFIGS
      },
      userForm: {
        username: "",
        tenantId: "",
        businessId: 0,
        staffCode: 0,
        companyName: "",
        roleId: 1,
        password: "",
        password2: "",
        mobile: "",
        status: 1,
        grade: 1
      }
    }
  },
  methods: {
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    onCancel (name) {
      this.$refs[name].resetFields();
    },
    onCorpSubmit () {
      this.$emit("loading-start");

      this.$refs.company.validate(async (valid) => {
        if (!valid) {
          this.$emit("loading-end");
          return;
        }

        try {
          const data = Object.assign({}, this.corpForm);
          const res = await bizApi.addWithoutToken(data);

          this.userForm.businessId = res;
          this.userForm.staffCode = res; // 创建用户的时候需要 staff code
          this.userForm.username = this.corpForm.contact;
          this.userForm.tenantId = this.corpForm.tenantId;
          this.active = 1;
        } catch (e) {
          this.$message.error(e.response.data.message || "公司添加失败");
        } finally {
          this.$emit("loading-end");
        }
      });
    },
    onUserSubmit () {
      this.$emit("loading-start");
      this.$refs.user.validate(async (valid) => {
        if (!valid) {
          return;
        }

        const submitData = Object.assign({}, this.userForm);
        submitData.companyName = this.corpForm.companyName;

        try {
          await userApi.addWithoutToken(submitData);
          this.$message.success("新用户已注册成功，请等待审核通过");
          this.$emit("close-edit-modal");
        } catch (e) {
          this.$message.error(e.response.data.message || "用户添加失败");
        } finally {
          this.$emit("loading-end");
        }
      });
    }
  }
}
</script>

<style>
.login-form-box .el-form-item__label {
  color: #FFFFFF;
}
</style>