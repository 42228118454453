var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-box" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "label-position": "left",
            "label-width": "0px",
          },
        },
        [
          _c("div", { staticClass: "login-title-box" }, [
            _c("h3", { staticClass: "login-title" }, [_vm._v("智能监控平台")]),
          ]),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "text",
                    "auto-complete": "off",
                    placeholder: "请输入用户名",
                  },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "username", $$v)
                    },
                    expression: "loginForm.username",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "user" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "password",
                    "auto-complete": "off",
                    placeholder: "密码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleLogin.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "password" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { size: "medium", type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v("登 录")])
                    : _c("span", [_vm._v("登 录 中...")]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "register-box" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.onOpenRegisterDialog },
                },
                [_vm._v("新用户注册")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dLoading,
                  expression: "dLoading",
                },
              ],
              attrs: {
                title: "新用户注册",
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                visible: _vm.dialogVisible,
                "close-on-click-modal": true,
                width: "600px",
              },
              on: { close: _vm.onCloseDialog },
            },
            [
              _c("edit", {
                ref: "edit",
                attrs: { visible: _vm.dialogVisible },
                on: {
                  "close-edit-modal": function ($event) {
                    return _vm.onCloseDialog(true)
                  },
                  "loading-start": _vm.loadingStart,
                  "loading-end": _vm.loadingEnd,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }