// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/login-bg.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".login-box {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  background-size: cover !important;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.login-box .el-dialog {\n  box-shadow: 10px 10px 10px rgba(48, 57, 71, 0.4);\n  border-radius: 0.4vw;\n  border: 3px solid #388ab3;\n  background: linear-gradient(#2071ab, #111c3b);\n}\n.login-box .el-dialog__title, .login-box .el-dialog__close {\n  color: #FFFFFF;\n}\n.corp-name-box {\n  position: absolute;\n  top: 40px;\n  left: 40px;\n  color: #FFFFFF;\n  padding-left: 55px;\n  background-size: 42px;\n  background-position: left;\n  background-repeat: no-repeat;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.corp-name-box > div:first-child {\n  margin-top: 3px;\n  font-size: 22px;\n  margin-bottom: 5px;\n}\n.login-form {\n  color: rgba(48, 49, 51, 0.8);\n  position: absolute;\n  box-shadow: 3px 3px 10px #AAA;\n  left: 34%;\n  top: 20vh;\n  background: #ffffff;\n  width: 400px;\n  height: 60vh;\n  max-height: 550px;\n  padding: 100px 25px 150px 25px;\n}\n.login-form .login-title-box {\n  text-align: center;\n  width: 100%;\n  margin: auto;\n}\n.login-form .login-title-box .login-title {\n  width: 150px;\n  margin: 0px auto 30px auto;\n  margin-bottom: 30px;\n  text-align: left;\n  padding-left: 17px;\n  height: 40px;\n  line-height: 40px;\n}\n.login-form .el-input {\n  height: 38px;\n}\n.login-form .el-input input {\n  height: 38px;\n}\n.login-form .input-icon {\n  height: 39px;\n  width: 20px;\n  margin-left: 2px;\n}\n.login-tip {\n  font-size: 13px;\n  text-align: center;\n  color: #bfbfbf;\n}\n.login-code {\n  width: 33%;\n  display: inline-block;\n  height: 38px;\n  float: right;\n}\n.login-code img {\n  cursor: pointer;\n  vertical-align: middle;\n}\n.register-box {\n  width: 100%;\n  text-align: right;\n  margin-top: -10px;\n  margin-bottom: 15px;\n  cursor: pointer;\n}", ""]);
// Exports
module.exports = exports;
